<template>
  <div class="key-box-info">
    <input
      class="key-box-info__id"
      type="text"
      :value="keyData"
      size="36"
      ref="datavalue"
      v-if="!isGraphql"
      readonly
    />
    <template v-else>
      <a :href="keyData" target="_blank" class="key-box-info__id link"
      id="link" title="GraphQL">{{keyData}}</a>
      <input
        class="key-box-info__id hide"
        type="text"
        :value="keyData"
        size="36"
        ref="datavalue"
        readonly
      />
    </template>
    <button class="key-box-info__copy-btn" @click="copyToClipboard()">
      <svg>
        <use
          v-bind="{ 'xlink:href': isKeyCopied ? '#copied-icon': '#copy-icon' }"
          ref="copy-btn"
        />
      </svg>
      <div class="tool-tip" :class="isKeyCopied ? 'copied' : null">{{toolTipText}}</div>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.key-box {
  &-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transition: visiblity 0.3s linear;
    &:hover {
      .key-box-info__copy-btn {
        visibility: visible;
      }
    }
    &__id {
      @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
      padding: 0;
      border: none;
      outline: none;
      color: var(--global--main--Color);
      width: size(330);
      padding-right: size(5);
      cursor: pointer;
      &::selection {
        background: transparent;
      }
      &.link {
        display: inline-block;
        cursor: pointer;
        color: var(--global--common-link--Color);
        overflow-x: auto;
        position: absolute;
        white-space: pre;
        background: var(--global--white--Color);
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    & &__copy-btn {
      visibility: hidden;
      position: relative;
      cursor: pointer;
      padding-left: size(5);
      &:hover,
      &:focus,
      &:active {
        outline: none;
      }
      svg {
        max-height: size(15);
        max-width: size(15);
      }
      & .tool-tip {
        @include font-styles($type: 'label', $size: 'sm', $weight: 'regular');
        position: absolute;
        bottom: size(30);
        left: size(-40);
        width: size(90);
        display: none;
        opacity: 0;
        padding: size(5) size(10) ;
        text-align: center;
        box-shadow: size(0) size(1) size(4) rgba(0, 0, 0, 0.15);
        color: var(--global--main--title--Color);
        border-radius: size(3);
        background: var(--global--white--Color);
        cursor: alias;
        &.copied {
          display: block;
          opacity: 1;
          color: var(--global--common-link--Color);
        }
      }
      &:hover .tool-tip {
        display: block;
        opacity: 1;
      }
    }
  }
  svg {
    fill: var(--global--main-title--Color);
  }
  .locked-info {
    p {
      @include font-styles($type: 'label', $size: 'lg', $weight: 'regular');
      display: inline-block;
      vertical-align: top;
      width: 92%;
    }
    svg {
      display: inline-block;
      vertical-align: top;
      width: size(12);
      height: size(12);
      margin-right: size(8);
      margin-top: size(4);
    }
  }
}
</style>
<script>
export default {
  name: 'KeyInnerBox',
  props: {
    keyData: String,
    isGraphql: Boolean,
  },
  data() {
    return {
      isKeyCopied: false,
      toolTipText: 'Copy Key',
    };
  },
  methods: {
    copyToClipboard() {
      const copyText = this.$refs.datavalue;
      copyText.select();
      copyText.setSelectionRange(0, 100000);
      document.execCommand('copy');
      this.isKeyCopied = true;
      this.toolTipText = 'Copied';
      setTimeout(() => {
        this.isKeyCopied = false;
        this.toolTipText = 'Copy Key';
      }, 10000);
    },
  },
};
</script>
