<template>
  <div class="screen-admin-page screen-admin-usaga-report coupon-info">
    <div>
      <div class="toolbar">
        <div class="title">
          <h1>Coupons</h1>
        </div>
        <AddCoupon :action="'add'" />
      </div>
    </div>
    <div class="filterables">
      <div class="filter-container">
        <ul class="filter-items">
          <li
            v-for="item in filterables"
            :key="item.key"
            :class="{
              active: values.title === item,
              selectable: !item.isCheckbox,
              selected: item.selected,
              hr: item.key === '-',
            }"
            @click="onSelectedFilteritem(item)"
          >
            {{item}}
          </li>
        </ul>
      </div>
      <rz-request-state :state="state" />
      <div
        class="usage-report admin-screen-content"
        v-if="state.status.isLoaded"
      >
        <div class="table-block">
          <div class="item-list-row item-list">
            <CouponInfoTable
              :title="values.title"
              :couponInfo="couponInfo.coupons"
            />
          </div>
          <div
            class="filter-pagination"
          >
            <div
              class="arrow left"
              :class="[couponInfo.pagination_params.current_page !== 1 ? null : 'disable']"
              @click="prevPage()"
            ></div>
            <div class="page-number">{{couponInfo.pagination_params.current_page}}</div>
            <div
              class="arrow right"
              :class="[couponInfo.pagination_params.current_page
              !== couponInfo.pagination_params.total_pages ? null : 'disable']"
              @click="nextPage()"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  watch,
  reactive,
  onMounted,
} from 'vue';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  ProjectList,
} from '@/store/modules/admin';
import CouponInfoTable from '@/components/admin/CouponInfoTable.vue';
import AddCoupon from '@/components/admin/AddCoupons.vue';

export default {
  components: {
    CouponInfoTable,
    AddCoupon,
  },
  setup() {
    const state = ref(new RequestState());
    let filterables = ref([]);
    const couponInfo = ref({});
    const pageNumbers = ref([]);
    const pageLimit = 10;
    const title = ref('Coupon Info');

    const values = reactive({
      show_active: true,
      title: 'Active Coupons',
    });

    filterables = [
      'Active Coupons',
      'Inactive Coupons',
    ];

    const onSelectedFilteritem = (item) => {
      if (item === 'Active Coupons') {
        values.show_active = true;
        values.title = 'Active Coupons';
      } else {
        values.show_active = false;
        values.title = 'Inactive Coupons';
      }
    };

    const fetchData = (val) => {
      state.value.status = RequestStateStatus.Loading;
      const params = val ?? {
        active: values.show_active,
        page: 1,
      };
      return ProjectList.fetchCouponInfo(params).then((resp) => {
        couponInfo.value = resp;
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    const prevPage = () => {
      if (couponInfo.value.pagination_params.current_page === 1) {
        return;
      }
      const pageNum = couponInfo.value.pagination_params.current_page - 1;
      const params = {
        page: pageNum,
        active: values.show_active,
      };
      fetchData(params);
    };
    const nextPage = () => {
      if (couponInfo.value.pagination_params.current_page === couponInfo.value.pagination_params.total_pages) {
        return;
      }
      const pageNum = couponInfo.value.pagination_params.current_page + 1;
      const params = {
        page: pageNum,
        active: values.show_active,
      };
      fetchData(params);
    };

    onMounted(() => {
      fetchData();
    });

    watch(() => values && values.show_active, () => {
      fetchData();
    });

    return {
      couponInfo,
      state,
      title,
      values,
      filterables,
      onSelectedFilteritem,
      pageNumbers,
      prevPage,
      nextPage,
      pageLimit,
    };
  },
};
</script>
<style lang="scss">
.coupon-info {
  .filterables {
    margin: 0 size(30);
    .filter-container {
      flex: 0 0 size(250);
    }
  }
  .filter-pagination {
    display: flex;
    justify-content: end;
    margin-bottom: size(50);
    .arrow {
      border-radius: size(5);
      border: size(1) solid var(--global--primary--Color);
      cursor: pointer;
      display: inline-block;
      &:after {
        content: '';
        border: solid var(--global--white--Color);
        border-width: 0 size(2) size(2) 0;
        display: inline-block;
        padding: size(3);
        vertical-align: middle;
      }
      &.left {
        padding: size(10) size(12) size(10) size(14);
        &:after {
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
        }
      }
      &.right {
        padding: size(10) size(14) size(10) size(12);
        &:after {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
      }
      &:hover {
        @include jump-up-animation;
      }
      &.disable {
        border: size(1) solid var(--global--gray--Color);
        cursor: not-allowed;
        &:after {
          border: solid var(--global--gray--Color);
          border-width: 0 size(2) size(2) 0;
        }
        &:hover {
          animation: none;
        }
      }
      }
    .page-number {
      margin: auto size(20);
    }
  }
  .table-block {
    display: inline-block;
    vertical-align: top;
    min-width: size(800);
    .item-list {
      padding-top: 0;
      table {
        tr {
          &:hover {
            background: transparent;
          }
          td {
            &:first-child{
              cursor: default;
              button {
                cursor: default;
              }
            }
          }
        }
      }
    }
  }
}
</style>
