<template>
  <div class="projects-info-list item-list item-report-summary coupon-info-table">
    <div
      class="summary-toolbar"
      v-if="title"
    >
      <h2>{{title}}</h2>
    </div>

    <table v-if="couponInfo">
      <thead>
        <tr>
          <th>Key</th>
          <th>Name</th>
          <th>Discount %</th>
          <th>Expiry Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="info in couponInfo"
          :key="info.key"
        >
          <td>
            {{info.key}}
          </td>
          <td>
            {{info.name}}
          </td>
          <td>
            {{info.discount_percentage}}
          </td>
          <td>
            {{$filters.formatDate(info.expiry_date)}}
          </td>
          <td>
            <AddCoupon  :action="'view'" :couponKey="info.key" :response="couponInfo"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import AddCoupon from '@/components/admin/AddCoupons.vue';

export default {
  components: {
    AddCoupon,
  },
  props: {
    couponInfo: Array,
    response: Object,
    title: {
      type: String,
      default: '',
    },
    total: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.coupon-info-table {
  width: 100%;
  &.item-report-summary {
    table {
      width: 95%;
      padding: 0 size(20);
      margin-right: auto;
      margin-left: auto;
      thead {
        th {
          border-bottom: size(1) solid var(--admin--text-level2);
          p {
            padding: 0;
            text-align: center;
            font-size: size(16);
            &.currency {
              padding: size(15) 0 0;
              text-align: center;
              font-size: size(12);
            }
          }
        }
      }
      tbody {
        tr {
          td {
            border-bottom: size(1) solid var(--admin--text-level2);
            &:nth-child(2), &:nth-child(3) {
              word-break: break-all;
              line-height: size(20);
            }
            span {
              vertical-align: top;
            }
          }
        }
      }
    }
  }
}
</style>
