<template>
  <div class="key-box">
    <h5 v-if="labelData" class="key-box__label">{{labelData}}</h5>
    <template v-if="keyData">
      <KeyInnerBox :keyData="keyData" :isGraphql="isGraphql"/>
    </template>
    <template  v-else>
      <div class="key-box-info-wrapper" v-for="key in keys" :key="key">
        <KeyInnerBox :keyData="key" :isGraphql="isGraphql"/>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.key-box {
  padding-bottom: size(6);
  border-bottom: size(1) solid var(--global--line--table--BackgroundColor);

  &__label {
    @include font-styles($type: 'label', $size: 'lg', $weight: 'bold');
    text-align: left;
    padding-bottom: size(6);
    color: var(--global--main--title--Color);
  }
}
</style>
<style lang="scss">
.key-box-info-wrapper {
  margin-bottom: size(15);
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
<script>
import KeyInnerBox from './KeyInnerBox.vue';

export default {
  name: 'KeyBox',
  props: {
    labelData: String,
    keyData: String,
    keys: Array,
    isGraphql: Boolean,
  },
  components: { KeyInnerBox },
  data() {
    return {
      isKeyCopied: false,
      toolTipText: 'Copy Key',
    };
  },
  methods: {
    copyToClipboard() {
      const copyText = this.$refs.datavalue;
      copyText.select();
      copyText.setSelectionRange(0, 100);
      document.execCommand('copy');
      this.isKeyCopied = true;
      this.toolTipText = 'Copied';
      setTimeout(() => {
        this.isKeyCopied = false;
        this.toolTipText = 'Copy Key';
      }, 10000);
    },
  },
};
</script>
