<template>
  <div class="preview-box add-coupon">
    <div class="preview-box-container">
      <div class="preview-box-action-container">
          <div class="preview-box-tools">
            <CouponConfirmView
              :actionButtonText="action"
              :title="action"
              :action="actionAddCoupon"
              @on-action-done="onActionDone"
            >
              <template v-slot:confirm>
                <div v-if="actionAddCoupon.key">
                  <label>Key</label>
                  <p class="text">{{actionAddCoupon.key}}</p>
                </div>
                <div>
                  <label>Type</label>
                  <select v-model="actionAddCoupon.applicable_product_type" placeholder="Choose a package type">
                    <option value="" disabled selected>Choose a package type</option>
                    <template v-for="type in PackageTypes">
                      <option v-if="type.key" :key="type" :value="type.value.key" >{{type.value.name}}</option>
                    </template>
                  </select>
                </div>
                <template v-if="actionAddCoupon.applicable_product_type">
                  <div>
                    <label>Packages</label>
                    <select v-model="actionAddCoupon.applicable_product" placeholder="Choose a package">
                      <option value="" disabled selected>Choose a package</option>
                      <template v-for="type in packageList">
                        <option v-if="type.key && type.type === actionAddCoupon.applicable_product_type"
                         :key="type" :value="type.key" >{{type.name}}</option>
                      </template>
                    </select>
                  </div>
                  <div>
                    <label>Name</label>
                    <input type="text" v-model="actionAddCoupon.name"/>
                  </div>
                  <div>
                    <label>Project key</label>
                    <input type="text" v-model="actionAddCoupon.project_key"/>
                  </div>
                  <div>
                    <label>Discount %</label>
                    <input type="number" v-model="actionAddCoupon.discount_percentage"/>
                  </div>
                  <div v-if="actionAddCoupon.applicable_product_type === PackageTypes._values.StandardPackage.key">
                    <label>Quantity</label>
                    <input type="number" v-model="actionAddCoupon.quantity"/>
                  </div>
                  <div>
                    <label>Notes</label>
                    <textarea
                      rows="6"
                      v-model="actionAddCoupon.notes"
                    />
                  </div>
                  <div>
                    <h2>Expiry Date</h2>
                    <template v-if="action !== 'view'">
                      <label>Select a type</label>
                      <select
                        v-model="actionAddCoupon.expiry_date_type"
                      >
                        <option value="" disabled selected>Select</option>
                        <option :value="'date'">Date</option>
                        <option :value="'days'">Days</option>
                      </select>
                    </template>
                    <template v-if="actionAddCoupon.expiry_date_type === 'days' && action !== 'view'">
                      <label>Days</label>
                      <input type="number" v-model="actionAddCoupon.expiry_date"/>
                    </template>
                    <template v-if="actionAddCoupon.expiry_date_type === 'date' || action === 'view'">
                      <label>Date</label>
                      <input
                        type="date"
                        v-model="actionAddCoupon.expiry_date"
                      />
                    </template>
                  </div>
                  <template v-if="action === 'view'">
                    <div v-if="action === 'view'">
                    <button @click="generateLink()" class="generate-link-btn">Generate link</button>
                    </div>
                    <KeyBox
                      v-if="actionAddCoupon.link"
                      class="credentials__key-box"
                      :keyData="actionAddCoupon.link"
                      :labelData="'Purchase link'"
                    />
                    <div class="button-wrapper" @click="invalidateCoupon(actionAddCoupon.key)">
                      <button class="btn btn-sm">Invalidate this Coupon</button>
                    </div>
                  </template>
                </template>
              </template>
            </CouponConfirmView>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  onMounted,
} from 'vue';
import { ProjectList, AdminAction, PackageTypes } from '@/store/modules/admin';
import moment from 'moment';
import Config from '@/config';
import KeyBox from '@/components/KeyBox.vue';

import CouponConfirmView from '@/components/admin/CouponConfirmView.vue';

export const datePickerFormat = (date) => moment.unix(date).format('DD/MM/YYYY');

export default {
  props: {
    action: String,
    couponKey: String,
    response: Object,
  },

  components: {
    CouponConfirmView,
    KeyBox,
  },

  setup(props) {
    const packageList = ref([]);
    const link = Config.sportsHost;

    const getUnixTimeStamp = (days) => {
      const timeStamp = moment().add(days, 'days').unix();
      return timeStamp;
    };

    const actionAddCoupon = ref(
      new AdminAction(() => ProjectList.addCoupon({
        name: actionAddCoupon.value.name,
        discount_percentage: parseInt(actionAddCoupon.value.discount_percentage, 10),
        applicable_product: actionAddCoupon.value.applicable_product,
        applicable_product_type: actionAddCoupon.value.applicable_product_type,
        notes: actionAddCoupon.value.notes,
        project_key: actionAddCoupon.value.project_key,
        quantity: actionAddCoupon.value.quantity ? parseInt(actionAddCoupon.value.quantity, 10) : 1,
        expiry_date: (actionAddCoupon.value.expiry_date_type === 'date'
           || !actionAddCoupon.value.expiry_date_type)
          ? moment(actionAddCoupon.value.expiry_date, 'YYYY-MM-DD').unix()
          : getUnixTimeStamp(actionAddCoupon.value.expiry_date),
      })),
    );

    const fetchData = () => {
      if (props.response && props.action === 'view' && props.couponKey) {
        const couponInfo = props.response.find((x) => x.key === props.couponKey);
        actionAddCoupon.value.key = couponInfo.key || '';
        actionAddCoupon.value.name = couponInfo.name || '';
        actionAddCoupon.value.discount_percentage = couponInfo.discount_percentage;
        actionAddCoupon.value.applicable_product = couponInfo.applicable_product;
        actionAddCoupon.value.applicable_product_type = couponInfo.applicable_product_type;
        actionAddCoupon.value.notes = couponInfo.notes;
        actionAddCoupon.value.project_key = couponInfo?.project_key?.key || '';
        actionAddCoupon.value.quantity = couponInfo.quantity;
        actionAddCoupon.value.expiry_date = moment.unix(couponInfo.expiry_date).format('YYYY-MM-DD');
      }
    };

    const fetchPackages = () => {
      const params = {
        active: true,
      };
      ProjectList.fetchPackageInfo(params).then((resp) => {
        packageList.value = resp.response;
      });
    };

    const invalidateCoupon = (key) => {
      ProjectList.invalidCoupon(key).then((resp) => {
        if (resp) {
          window.location.reload();
        }
      });
    };

    const generateLink = () => {
      const copyLink = actionAddCoupon.value.project_key
        ? `${link}user/project/${
          actionAddCoupon.value.project_key}/wizard?step=payment&prepay=${
          actionAddCoupon.value.applicable_product}|50&quantity=${
          actionAddCoupon.value.quantity}&couponKey=${actionAddCoupon.value.key}`
        : `${link}user/project/create/?package=${actionAddCoupon.value.applicable_product}&quantity=${
          actionAddCoupon.value.quantity}&couponKey=${actionAddCoupon.value.key}`;
      actionAddCoupon.value.link = copyLink;
    };

    onMounted(() => {
      fetchData();
      fetchPackages();
    });

    const resetForm = {
      name: '',
      discount_percentage: '',
      applicable_product: '',
      applicable_product_type: '',
      notes: '',
      quantity: '',
      project_key: '',
      expiry_date: '',
    };

    const onActionDone = () => {
      Object.assign(actionAddCoupon.value, resetForm);
      window.location.reload();
    };

    return {
      actionAddCoupon,
      onActionDone,
      PackageTypes,
      packageList,
      generateLink,
      invalidateCoupon,
    };
  },
};
</script>
<style lang="scss">
.add-coupon {
  &.preview-box {
    .preview-box-tools {
      margin: 0 auto;
      padding: 0!important;
      .btn {
        &.btn-sm {
          margin-bottom: 0;
          margin-right: 0;
        }
      }
      .error-msg {
        padding: size(10) 0;
        color: var(--global--error--BackgroundColor);
      }
      .body {
        h2 {
          border-bottom: size(1) solid var(--global--supportive--Color);
          padding-bottom: size(10);
          margin: size(20) 0;
        }
        >div {
          label {
            margin-bottom: size(6);
            color: var(--global--supportive--Color);
            font-size: size(12);
            line-height: size(18);
          }
          select, input, textarea, p {
            display: block;
            width: 100%;
            border-radius: size(4);
            color: var(--global--white--Color);
            padding: size(6);
            font-size: size(14);
            line-height: size(20);
            background: none;
            outline: var(--global--supportive--Color);
            border: size(1) solid var(--global--supportive--Color);
          }
          select {
            border: 0;
            outline: size(1) solid var(--global--supportive--Color);
            border-right: size(10) solid transparent;
            appearance: none;
            background-image: url('../../assets/dropdown-white.svg');
            background-repeat: no-repeat;
            background-position: top size(5) right 0;

            option {
              color: var(--global--main--Color);
            }
          }

          input[type="date"] {
            &::-webkit-calendar-picker-indicator {
              filter: invert(1);
            }
          }
        }
      }
      p {
        &.text {
          display: inline-block;
          padding: 0;
          color: var(--global--white--Color);
          font-size: size(14);
          font-style: normal;
          margin-bottom: size(25);
        }
      }
      label {
        display: inline-block;
        vertical-align: top;
        margin-right: size(25);
        margin-top: 0;
        min-width: size(180);
        span {
          color: var(--global--error--BackgroundColor);
          padding-left: size(2);
        }
      }
      .tools {
        margin-top: size(25);
        text-align: right;
      }
      .error {
        padding: size(20) 0;
        color: var(--global--error--BackgroundColor);
        font-size: size(15);
      }
      .start-date, .end-date {
        display: inline-block;
        svg {
          width: size(12);
        }
      }
    }
    .generate-link-btn {
      text-decoration: underline;
      font-size: size(14);
      line-height: size(18);
      cursor: pointer;
      color: var(--admin--color-level2);
    }
  }
  .button-wrapper {
    text-align: right;
    margin-top: size(30);
  }
  .key-box {
    padding-bottom: 0;
    border-bottom: 0;

    margin-top: size(10);

    h5 {
      margin-bottom: size(6);
      color: var(--global--supportive--Color);
      font-size: size(12);
      line-height: size(18);
      padding-bottom: 0;
    }

    .key-box-info {
      input {
        margin-bottom: 0;
      }

      svg {
        filter: invert(1);
      }
    }
  }
}
</style>
